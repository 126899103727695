export const routes = [
	{
		includeInFooter: true,
		includeInHeader: false,
		IncludeInProfile: false,
		label: 'About Us',
		path: '/AboutUs',
		isNonReactPage: true,
	},
	{
		includeInFooter: true,
		includeInHeader: false,
		IncludeInProfile: false,
		label: 'Editorial Policy',
		path: '/EditorialPolicy',
		isNonReactPage: true,
	},
	{
		includeInFooter: true,
		includeInHeader: false,
		IncludeInProfile: false,
		label: 'Privacy Statement',
		path: '/Privacy',
		isNonReactPage: true,
	},
	{
		includeInFooter: true,
		includeInHeader: false,
		IncludeInProfile: false,
		label: 'Terms & Conditions',
		path: '/TermsAndConditions',
		isNonReactPage: true,
	},
	{
		includeInFooter: true,
		includeInHeader: false,
		IncludeInProfile: false,
		label: 'Contact Us',
		path: '/contactus',
		isNonReactPage: true,
	},
	{
		includeInFooter: false,
		includeInHeader: false,
		includeInProfile: true,
		label: 'My Profile',
		path: '/myprofile/info',
		key: 'myProfile',
		isNonReactPage: true,
	},
	{
		includeInFooter: false,
		includeInHeader: false,
		includeInProfile: true,
		label: 'Password / Security',
		path: '/identity/profile/security',
		key: 'passwordAndSecurity',
		isNonReactPage: true,
	},
	{
        includeInFooter: false,
		includeInHeader: true,
		includeInProfile: false,
        isProtected: false,
        key: 'allDeals',
        label: 'ALL DEALS',
        labelMobile: 'All Deals',
        path: '/home',
        isNonReactPage: true,
    },
	{
        
        includeInFooter: false,
		includeInHeader: true,
		includeInProfile: false,
        isProtected: true,
        key: 'findAProvider',
        label: 'FIND A PROVIDER',
        labelMobile: 'Find A Provider',
        path: '/',
        isNonReactPage: true,
        subItems: [
			// need to get from API call
		]
    },
	{
        includeInFooter: false,
		includeInHeader: true,
		includeInProfile: false,
        isProtected: false,
        key: 'healthResources',
        label: 'HEALTH RESOURCES',
        labelMobile: 'Health Resources',
        path: '/home',
        isNonReactPage: true,
        subItems: [
            {
                isProtected: false,
                key: 'resourceLibrary',
                label: 'Resource Library',
                path: '/ResourceLibrary',
                isNonReactPage: true,
            },
        ]
    },
	{
        includeInFooter: false,
		includeInHeader: true,
		includeInProfile: false,
        isProtected: false,
        key: 'howItWorks',
        label: 'HOW IT WORKS',
        labelMobile: 'How It Works',
        path: '/HowItWorks',
        isNonReactPage: true,
    },
	
];

