const envConfigs = {
	dev: {
		googleTag: {
			gTagId: 'G-4VW2LRNCMH',
			enabled: true,
		},
		applicationInsightConnectionString:
			'InstrumentationKey=941a5f78-b565-4e39-96a3-1bc4e38376e2;IngestionEndpoint=https://westus2-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com/',
	},
	tst: {
		googleTag: {
			gTagId: 'G-V5TCZFWVY3',
			enabled: true,
		},
		applicationInsightConnectionString:
			'InstrumentationKey=57573486-a473-44ec-a3ee-3e5836888b1d;IngestionEndpoint=https://westus2-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com/',
	},
	stg: {
		googleTag: {
			gTagId: '',
			enabled: false,
		},
		applicationInsightConnectionString:
			'InstrumentationKey=0fd14837-b806-43f4-808b-4fdcd15d73a3;IngestionEndpoint=https://westus2-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com/',
	},
	prod: {
		googleTag: {
			gTagId: 'G-91T5FJ4FBH',
			enabled: true,
		},
		applicationInsightConnectionString:
			'InstrumentationKey=d73bbc1e-c31f-4d89-bf36-2ddd3fe38f32;IngestionEndpoint=https://westus2-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com/',
	},
};

// function used to get different config ids based on the env passed in
// see helper util getEnvironment for how to get the env we are currently in
const getEnvConfig = (env, configName) => {
	if (!envConfigs[env]) {
		if (process.env.NODE_ENV === 'development') {
			// eslint-disable-next-line
			console.warn('config doesnt exist');
		}
		return null;
	}
	return envConfigs[env][configName];
};

export default getEnvConfig;