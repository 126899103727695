import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ASHLogo from '../logos/ASHLogo.svg';
import { copyright } from './data';
import { routes } from '../../utils/routeList';

const menuItems = routes.filter(r => r.includeInFooter);

const FooterMenu = () => (
	<Grid container direction="row" fontSize="14px" mt={8} spacing={3.5}>
		{menuItems.map(menu => (
			<Grid item xs={6} lg={3} key={menu.path}>
				<Link
					color="inherit"
					sx={{ fontSize: '1rem !important', fontFamily: 'Inter, -apple-system, Segoe UI, Helvetica Neue, sans-serif !important', fontWeight:'normal !important' }}
					href={menu.path}
					underline="hover"
				>
					{menu.label}
				</Link>
			</Grid>
		))}
	</Grid>
);

export default function Footer() {
	return (
		<Box
			component="footer"
			role="contentinfo"
			sx={{
				backgroundColor: 'grey.50',
				color: 'text.primary',
				p: 0,
			}}
		>
			<Box>
				<Container>
					<Grid container mb={6}>
						<Grid item xs={12} md={4} lg={3.5}>
							<Stack>
								<Typography mt={7} mb={1} variant="overline" component="p">
									A PRODUCT OF
								</Typography>
								<Box sx={{ width: '15rem', height: '2rem' }} mb={2}>
									<Link
										href="https://www.ashcompanies.com/"
										underline="none"
										target="_blank"
										rel="noopener"
									>
										<img src={ASHLogo} alt="ASH Logo" />
									</Link>
								</Box>
							</Stack>
						</Grid>
						<Grid item display="flex" flexWrap="wrap" md={8}>
							<FooterMenu />
						</Grid>
					</Grid>
					<Divider
						sx={{ borderColor: 'rgba(0, 0, 0, 0.12) !important', margin: '2.5rem 0 !important', borderWidth: '0 0 thin !important', borderStyle: 'solid !important' }}
						orientation="horizontal"
					/>
					<Typography variant="body2" mb={3}>
						<OpenInNewIcon
							sx={{ fontSize: 16, top: '0.1rem', position: 'relative' }}
						/>{' '}
						Clicking on these links will take you away from ChooseHealthy.com.
						ChooseHealthy is not responsible for any content or the privacy
						practices of external websites.
					</Typography>
					<Typography variant="body2" mt={3} mb={3}>
						The ChooseHealthy is a product of American Specialty Health Group, Inc. and ASH Technologies, Inc. (dba ASH Technologies of Delaware, Inc. in the state of Pennsylvania); all are 
						subsidiaries of American Specialty Health Incorporated.  ASH Group is a licensed discount program in the following states: OK, KS, CT, MD, WV, NH, IN, IL, TN, SC, UT, MT, NE, LA, SC, DE, TX, FL, NV, RI, and OR. ChooseHealthy complies with applicable Federal civil right laws and does not discriminate on the basis of race, color, national origin, age, disability, or sex. 
						ChooseHealthy and the ChooseHealthy logo are trademarks of ASH. Other names and logos may be trademarks of their respective owners.
					</Typography>
				</Container>
			</Box>
			<Box sx={{ backgroundColor: 'primary.main' }} pt={4} pb={4} pl={2} pr={2}>
				<Container display="flex">
					<Stack
						spacing={2}
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent="space-between"
					>
						<Typography
							fontSize="14px"
							color="white"
							textAlign={{ xs: 'center' }}
						>
							{copyright}
						</Typography>
					</Stack>
				</Container>
			</Box>
		</Box>
	);
}
