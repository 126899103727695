import React from 'react';
import ReactDOM from 'react-dom/client';
import EveThemeProvider from '@ashn/eve-themes-react/EveThemeProvider';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import getEnvConfig from '../../utils/getEnvConfig';
import getApiEnvironment from '../../utils/getApiEnvironments';
import App from './App';

const appInsights = new ApplicationInsights({
	config: {
		connectionString: getEnvConfig(
			getApiEnvironment(),
			'applicationInsightConnectionString'
		),
		enableAutoRouteTracking: true,
	},
});
appInsights.loadAppInsights();
appInsights.trackPageView();
const root = ReactDOM.createRoot(document.getElementById('footer'));
root.render(
	<EveThemeProvider themeKey="ch" themeMode="light">
		<App />
	</EveThemeProvider>
);

